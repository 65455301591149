footer {
	.footer-top {
		background-color: $base;
		border-bottom: 2px solid $beige;
		.footer-flexbox {
			padding: 2rem 0;
			font-family: $sora;
			font-size: rem(15);
			color: $white;
			display: flex;
			column-gap: 1rem;
			@include bdown(md) {
				flex-direction: column;
				row-gap: 1rem;
				align-items: center;
			}
			&-left {
				flex-basis: 25%;
				img {
					@include bdown(sm) {
						max-width: 85%;
					}
					@include bdown(xs) {
						max-width: 70%;
					}
				}
			}
			&-middle {
				flex-basis: 50%;
				display: flex;
				justify-content: center;
				ul {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					column-gap: 2rem;
					@include bdown(xs) {
						grid-template-columns: repeat(1, 1fr);
					}
					li {
						display: flex;
						column-gap: 0.5rem;
						align-items: center;
						border-bottom: 2px solid $green;
						max-width: 250px;
						padding-bottom: 0.2rem;
						p {
							line-height: 1;
						}
						i {
							color: $blue;
							font-size: rem(19);
						}
						&:nth-last-child(-n + 2) {
							border-color: transparent;
							@include bdown(xs) {
								border-color: $green;
								&:nth-last-child(1) {
								border-color: transparent;
							}
							}
						}
						@include bdown(md) {
							margin-bottom: 1rem;
						}
					}
				}
			}
			&-right {
				flex-basis: 25%;
				&-text {
					color: $gold;
					p {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.footer-bottom {
		padding-top: 1rem;
		padding-bottom: 1rem;
		background-color: $darkpurple;
		p,
		ul {
			margin-bottom: 0;
		}
		&-flexbox {
			display: flex;
			justify-content: space-between;
			color: $white;
			@include bdown(sm) {
				display: block;
				text-align: center;
			}
			&-text {
				font-family: $sora;
				font-size: rem(16);
				@include bdown(sm) {
					margin-bottom: 1rem;
				}
			}
			&-wrapper {
				ul {
					display: flex;
					column-gap: 1.5rem;
					font-family: $montserrat;
					font-size: rem(14);
					li {
						i {
							color: $red;
							font-size: rem(16);
						}
						a {
							text-decoration: underline !important;
						}
					}
					@include bup(sm) {
						li:nth-child(n + 2) {
							list-style-type: disc !important;
						}
					}
					@include bdown(sm) {
						flex-wrap: wrap;
						justify-content: center;
						row-gap: 1rem;
						li {
							flex: 0 0 40%;
							&:nth-child(even) {
								list-style-type: disc !important;
							}
						}
					}
				}
			}
		}
	}
}
