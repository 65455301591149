form {
	position: relative;
	input,
	select,
	textarea {
		&.has-danger {
			border-bottom: 1px solid #f00 !important;
			// border-color: #f00 !important;
		}
	}
	.input-wrapper {
		&.has-danger {
			border-color: #f00 !important;
			&:before,
			&:after {
				background-color: #f00 !important;
			}
		}
	}
	input[type="radio"].has-danger,
	input[type="checkbox"].has-danger {
		& + label {
			&:before {
				border-color: #f00 !important;
			}
		}
	}
	::placeholder {
		color: $gray2;
	}
	input,
	select,
	textarea {
		font-size: 1rem;
		color: $base;
		font-family: inherit;
		padding: 1em 2em;
	}
	input,
	textarea,
	select,
	.select-wrapper {
		width: 100%;
		border: 1px solid $gray;
	}
	input {
		min-height: 3rem;
	}
	.select-wrapper {
		min-height: 3rem;
		position: relative;
		&:before {
			position: absolute;
			right: 1em;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.5em;
			z-index: 10;
			color: $purple;
			pointer-events: none;
		}
		&:after {
			position: absolute;
			display: block;
			content: "";
			right: 0;
			top: 0;
			width: 1.6em;
			height: 100%;
			font-size: 2em;
			z-index: 5;
			pointer-events: none;
		}
	}
	select {
		min-height: 3rem;
		width: 100%;
		border-radius: 0;
		&:disabled {
			color: $gray;
		}
		option [disabled="disabled"] {
			color: $gray;
		}
	}
	p {
		&.question {
			@include font-count(24, 33);
		}
		&.c-info {
			@include font-count(16, 24);
			@include letter-spacing(100);
		}
	}
	label {
		text-align: left;
		display: block;
		padding-bottom: 0.2em;
		&.file_upload {
			display: inline-flex;
			align-items: flex-start;
			input[type="file"] {
				position: absolute;
				visibility: hidden;
				opacity: 0;
				display: none;
			}
			mark {
				background-color: transparent;
				color: #fff;
				opacity: 1;
				font-size: 1rem;
				margin-left: 0.5em;
				@include transition;
			}
			.btn {
				cursor: pointer;
				&.empty {
					color: #fff;
				}
				&:not(.empty):hover {
					& + mark {
						opacity: 0;
					}
				}
			}
		}
	}
	.row {
		padding-top: 0px;
		padding-bottom: 0px;
		.col {
			&.file-upload-container {
				text-align: left;
				&.has-danger {
					border: 1px solid #f00;
					mark {
						color: #f00;
					}
				}
			}
			@include bdown(md) {
				margin-bottom: 15px;
			}
		}
	}
	.checkbox input {
		display: none;
	}
	.checkbox label {
		position: relative;
		padding-left: 4em;
		line-height: 3em;
		cursor: pointer;
		&:before {
			content: "";
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			width: 3em;
			height: 3em;
			background-color: #fff;
		}
		&:after {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			padding-left: 0.4em;
			content: "";
			@include font-count(30, 48);
			color: $purple;
		}
	}

	.checkbox input:checked + label {
		&:after {
			content: "V";
		}
	}
	&.search-form {
		flex-grow: 1;
		display: flex;
		position: relative;
		max-width: 360px;
		color: $white;
		font-family: $montserrat;

		@include bdown(sm) {
			width: 220px;
		}
		@include bdown(xs) {
			display: none;
		}
		::placeholder {
			font-size: rem(14);
			font-weight: 500;
			color: $white;
		}
		input {
			min-height: auto;
			background-color: transparent;
			border: none;
			padding: 0.2rem 1.5rem 0.2rem 0.5rem;
			border-bottom: 2px solid $whitealt;
			font-size: rem(14);
			color: $white;
			font-weight: 500;

			@include transition;
			@include bdown(xs) {
				opacity: 0;
			}
		}

		button {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			border: none;
			background-color: transparent;
			color: $white;
			i {
				font-size: rem(20);
				font-weight: 600;
			}
		}
	}
}

.shown {
	display: block !important;
	position: absolute !important;
	top: 105%;
	left: 0;
	width: 100%;
	z-index: 5;
	background-color: $white;

	input {
		// color: $base!important;
		background-color: $gray !important;
		opacity: 1 !important;
		width: 100% !important;
		max-width: 100% !important;
		padding-top: 0.2rem !important;
		padding-bottom: 0.2rem !important;
	}
}

.signup-form {
	display: flex;
	max-width: 670px;
	@include bdown(xs) {
		display: block;
	}
	input {
		border: none;
		box-shadow: inset 6px 8px 26px rgba(0, 0, 0, 0.03);
		@include bdown(md) {
			flex-basis: 71%;
		}
		@include bdown(xs) {
			margin-bottom: 1rem;
		}
	}
	::placeholder {
		font-size: rem(16);
		font-weight: 500;
		font-family: $montserrat;
	}
}

.submit-form {
	max-width: 1250px;
	margin-right: auto;
	input,
	textarea {
		border: 2px solid #dfe5c1;
	}
	&-row {
		display: grid;
		grid-template-columns: 4fr 2fr 3fr;
		column-gap: 1rem;
		margin-bottom: 2rem;
		@include bdown(sm) {
			display: block;
		}
		&-text {
			font-family: $sora;
			font-size: rem(19);
			font-weight: 700;
			color: $green;
			p {
				margin-bottom: 0.5rem;
			}
		}
	}
	&-area {
		display: grid;
		grid-template-columns: 1fr 3fr;
		column-gap: 1rem;
		align-items: center;
		margin-bottom: 1rem;

		.submit-form-row-text {
			text-align: end;
		}
		@include bdown(sm) {
			display: block;
			.submit-form-row-text {
				text-align: start;
			}
		}
	}
	&-ticket {
		display: grid;
		grid-template-columns: 2fr 3fr;
		column-gap: 1rem;
		align-items: center;
		margin-bottom: 1rem;
		.submit-form-row-text {
			text-align: end;
			
		}
		@include bdown(sm) {
			display: block;
			.submit-form-row-text {
				text-align: start;
			}
		}
	}
}
