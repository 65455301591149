.green-base-header {
	position: relative;
	margin-bottom: 1rem;
	&::before {
		content: "";
		width: 2.9rem;
		height: 0.3rem;
		background-color: $green;
		position: absolute;
		transform: translateY(-50%);
		top: 25px;
		transform: translateY(-50%);
		@include bdown(md) {
			top: 19px;
			width: 2.5rem;
		}
	}
	h2,
	h3 {
		padding-left: 3.5rem;
		margin-bottom: 0;
		@include bdown(md) {
			padding-left: 3rem;
		}
	}
}

.green-base-header-h3 {
	position: relative;
	margin-bottom: 1rem;
	&::before {
		content: "";
		width: 2.9rem;
		height: 0.3rem;
		background-color: $green;
		position: absolute;
		transform: translateY(-50%);
		top: 21px;
		transform: translateY(-50%);
		@include bdown(md) {
			top: 16px;
			width: 2.5rem;
		}
	}

	h3 {
		padding-left: 3.5rem;
		margin-bottom: 0;
		@include bdown(md) {
			padding-left: 3rem;
		}
	}
}

.green-header-h3 {
	position: relative;
	margin-bottom: 1rem;
	&::before {
		content: "";
		width: 2.8rem;
		height: 0.3rem;
		background-color: $green;
		position: absolute;
		transform: translateY(-50%);
		top: 21px;
		transform: translateY(-50%);
		@include bdown(md) {
			top: 16px;
			width: 2.5rem;
		}
	}

	h3 {
		color: $green;
		padding-left: 3.5rem;
		margin-bottom: 0;
		@include bdown(md) {
			padding-left: 3rem;
		}
	}
}

.green-header {
	position: relative;
	margin-bottom: 1rem;
	&::before {
		content: "";
		width: 3rem;
		height: 0.3rem;
		background-color: $green;
		position: absolute;
		transform: translateY(-50%);
		top: 25px;
		transform: translateY(-50%);
		@include bdown(md) {
			top: 19px;
			width: 2.5rem;
		}
	}
	h2,
	h3 {
		color: $green;
		padding-left: 3.5rem;
		margin-bottom: 0;
		@include bdown(md) {
			padding-left: 3rem;
		}
	}
}

.purple1-purplealt-header {
	position: relative;
	margin-bottom: 1rem;
	&::before {
		content: "";
		width: 2.9rem;
		height: 0.3rem;
		background-color: $purple1;
		position: absolute;
		transform: translateY(-50%);
		top: 25px;
		transform: translateY(-50%);
		@include bdown(md) {
			top: 19px;
			width: 2.5rem;
		}
	}
	h2,
	h3 {
		color: $purplealt;
		padding-left: 3.5rem;
		margin-bottom: 0;
		@include bdown(md) {
			padding-left: 3rem;
		}
	}
}

.purple1-green-header {
	position: relative;
	margin-bottom: 1rem;
	&::before {
		content: "";
		width: 2.9rem;
		height: 0.3rem;
		background-color: $purple1;
		position: absolute;
		transform: translateY(-50%);
		top: 25px;
		transform: translateY(-50%);
		@include bdown(md) {
			top: 19px;
			width: 2.5rem;
		}
	}
	h2,
	h3 {
		color: $green;
		padding-left: 3.5rem;
		margin-bottom: 0;
		@include bdown(md) {
			padding-left: 3rem;
		}
	}
}

.white-base-header {
	position: relative;
	margin-bottom: 1rem;
	&::before {
		content: "";
		width: 2.9rem;
		height: 0.3rem;
		background-color: $white;
		position: absolute;
		top: 21px;
		transform: translateY(-50%);
		@include bdown(md) {
			top: 15px;
			width: 2.5rem;
		}
	}
	h3 {
		color: $base;
		padding-left: 3.5rem;
		margin-bottom: 0;
		@include bdown(md) {
			padding-left: 3rem;
		}
	}
}

.main-slider {
	padding-top: 0;
	padding-bottom: 0;
	.top-hero {
		display: flex;
		@include bdown(md) {
			flex-wrap: wrap;
		}
		&-left {
			flex-grow: 1;
			flex-basis: 50%;
			&.dark-background {
				background-color: $darkgray;
				padding-left: 0;
				padding-right: 4rem;
				@include bdown(nm) {
					padding-left: 1rem;
					padding-right: 1rem;
				}
			}
			background-color: $base;
			color: $beige;
			padding: 2rem 2rem 2rem 6rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@include bdown(lg) {
				padding-left: 3rem;
			}
			@include bdown(nm) {
				padding-left: 2rem;
				padding-right: 1rem;
			}
			@include bdown(md) {
				flex-basis: 100%;
				order: 1;
			}
			&-info {
				max-width: 700px;

				h5 {
					color: $blue;
				}
				h1 {
					color: $yellow;
				}
			}
			&-events {
				margin-top: 3rem;
				display: flex;
				column-gap: 2rem;
				align-items: flex-start;
				@include bdown(md) {
					column-gap: 3rem;
				}
				@include bdown(xs) {
					display: block;
				}
				&-flexbox {
					max-width: 290px;
					display: flex;
					column-gap: 1rem;
					align-items: flex-start;
					margin-bottom: 1rem;
					i {
						font-size: rem(40);
						color: $green;
					}
					.h6-style {
						color: $gold;
						p {
							margin-bottom: 0;
						}
					}
					&-text {
						font-size: rem(14);
						font-family: $montserrat;
						font-weight: 500;
						color: $white;
					}
				}
			}
		}
	}
	.green-row {
		background-color: $green;
		font-family: $sora;
		color: $white;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: rem(19);
		font-weight: 700;
		&-flexbox {
			display: flex;
			align-items: center;
			column-gap: 0.5rem;
			p {
				margin-bottom: 0;
			}
			@include bdown(sm) {
				display: block;
			}
		}
		&-link {
			@include bdown(sm) {
				padding-left: 2rem;
			}
		}
		&-text {
			position: relative;
			font-size: rem(22);
			font-weight: 400;
			padding-left: 2rem;
			&::before {
				content: "\f071";
				font-family: $fontawesome;
				color: $red;
				font-weight: 600;
				position: absolute;
				left: 0;
				display: block;
			}
			@include bdown(sm) {
				margin-bottom: 0.5rem;
			}
		}
	}
}

.full-slider {
	background-color: rgba($color: $gray, $alpha: 0.56);
	padding-top: 0;
	padding-bottom: 0;
	.fw-slider-flexbox {
		display: flex;
		column-gap: 1rem;
		align-items: flex-start;
		@include bdown(lg) {
			display: block;
		}
		&-left {
			position: relative;
			flex-basis: 45%;
			display: flex;
			column-gap: 1rem;
			@include bdown(lg) {
				justify-content: center;
			}
			@include bdown(xs) {
				display: block;
				padding-bottom: 120px;
			}

			&-image {
				display: flex;
				flex-basis: 50%;
				img {
					width: 100%;
				}
				@include bdown(lg) {
					flex-basis: 30%;
				}
				@include bdown(nm) {
					flex-basis: 40%;
				}
				@include bdown(sm) {
					flex-basis: 50%;
				}
				@include bdown(xs) {
					margin-bottom: 1rem;
				}
			}
			&-block {
				flex-basis: 50%;
				img {
					width: 100%;
				}
				@include bdown(lg) {
					flex-basis: 30%;
				}
				@include bdown(nm) {
					flex-basis: 40%;
				}
				@include bdown(sm) {
					flex-basis: 50%;
				}
			}
			&-info {
				position: absolute;
				max-width: 440px;
				bottom: 0;
				right: -1rem;
				z-index: 50;
				background-color: $yellow;
				padding: 1.5rem 2rem;
				font-size: rem(17);
				color: $darkpurple;
				@include bdown(lg) {
					bottom: -1rem;
					right: 10rem;
				}
				@include bdown(nm) {
					right: 5rem;
				}
				@include bdown(md) {
					right: 7rem;
					max-width: 340px;
				}
				@include bdown(sm) {
					right: 2rem;
					max-width: 275px;
					padding: 1rem 1rem;
				}
				@include bdown(xs) {
					right: 0;
				}
				&-video {
					display: flex;
					align-items: center;
					p {
						margin-bottom: 0;
					}

					i {
						color: $green;
						font-size: rem(42);
					}
					span {
						display: block;
						width: 7rem;
						height: 2px;
						margin-left: 1rem;
						margin-right: 1rem;
						background-color: rgba(
							$color: $darkpurple,
							$alpha: 0.2
						);
					}
				}
			}
		}
		&-right {
			flex-basis: 50%;
			padding: 2rem 2rem 0 4rem;
			@include bdown(sm) {
				padding-left: 1rem;
				padding-right: 0;
			}
			&-subheader {
				font-family: $montserrat;
				font-size: rem(16);
				font-weight: 600;
				color: $green;
			}
			&-header {
				position: relative;
				&::before {
					content: "";
					position: absolute;
					width: 3rem;
					height: 0.35rem;
					background-color: $white;
					left: -4rem;
					top: 1rem;
					@include bdown(sm) {
						width: 2rem;
						height: 0.25rem;
						left: -2.5rem;
					}
				}
			}
			&-text {
				font-weight: 500;
				color: rgba($color: $purplealt, $alpha: 0.7);
			}
		}
	}
}

.service-banner {
	padding-top: 0;
	padding-bottom: 0;
	.top-hero {
		display: flex;
		@include bdown(md) {
			flex-wrap: wrap;
		}
		&-left {
			flex-basis: 61%;
			background-color: $green;
			color: $white;
			padding: 4rem 2rem 3rem 6rem;
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@include bdown(lg) {
				padding-left: 3rem;
			}
			@include bdown(nm) {
				padding-left: 2rem;
				padding-right: 1rem;
			}
			@include bdown(md) {
				flex-basis: 100%;
				order: 1;
				padding-left: 1rem;
			}
			&-info {
				max-width: 900px;

				h5 {
					color: $purplealt;
					margin-bottom: 0;
				}
				h1 {
					color: $white;
					margin-bottom: 1rem;
				}
			}
		}
		&-right {
			flex-basis: 39%;
			display: flex;
			img {
				object-fit: cover;
				width: 100%;
			}
			@include bdown(md) {
				flex-basis: 100%;
			}
		}
	}
}

.more-link {
	font-family: $montserrat;
	font-size: rem(18);
	font-weight: 700;
	a {
		border-bottom: 2px solid rgba($color: $base, $alpha: 0.3);
	}
	&::before {
		content: "\e902";
		font-family: $icomoon;
		display: inline;
		vertical-align: middle;
		margin-right: 0.5rem;
	}
}

.back-link {
	font-family: $montserrat;
	font-size: rem(14);
	font-weight: 500;
	color: $green;
	a {
		text-decoration: none;
	}
	&::before {
		content: "\e901";
		font-family: $icomoon;
		display: inline;
		vertical-align: middle;

		color: $green;
	}
}

.quick {
	background-color: $lightgreen;
	&-wrapper {
		display: grid;
		grid-template-columns: 2fr repeat(3, 1fr);
		@include bdown(md) {
			grid-template-columns: 2fr repeat(2, 1fr);
		}
		@include bdown(sm) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include bdown(xs) {
			grid-template-columns: repeat(2, 1fr);
		}
		&-bigitem {
			grid-row: 1/3;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 3rem;
			@include bdown(nm) {
				padding: 3rem 2rem;
			}
			@include bdown(md) {
				grid-row: 1/4;
				padding: 2rem;
			}
			@include bdown(sm) {
				grid-row: 1/2;
				grid-column: 1/4;
				img {
					max-width: 70%;
				}
			}
			@include bdown(xs) {
				grid-row: 1/2;
				grid-column: 1/3;
			}
		}

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 3rem 2rem;
			@include bdown(nm) {
				padding: 3rem 1rem;
			}
			@include bdown(md) {
				padding: 2rem 1rem;
			}
			@include bdown(sm) {
				img {
					max-width: 40px;
					max-height: 40px;
				}
			}
			p {
				font-family: $montserrat;
				font-size: rem(22);
				font-weight: 700;
				color: $white;
				margin-top: 1rem;
			}
		}
	}
}

.navigation {
	background-color: rgba($color: $beige, $alpha: 0.64);
	&-wrapper {
		justify-content: center;
		align-items: flex-start;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		column-gap: 1rem;
		@include bdown(nm) {
			grid-template-columns: repeat(4, 1fr);
		}
		@include bdown(md) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include bdown(sm) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include bdown(xs) {
			display: block;
		}
		&-item {
			background-color: rgba($color: $purple1, $alpha: 0.06);
			padding: 1rem 1.5rem;
			margin-bottom: 1rem;
			@include bdown(nm) {
				padding: 1rem;
			}
			@include bdown(xs) {
				max-width: 300px;
				margin-left: auto;
				margin-right: auto;
			}
			.h2-style {
				p {
					margin-bottom: 0.5rem;
				}
			}
			ul {
				li {
					position: relative;
					margin-left: 0;
					margin-bottom: 0.5rem;
					&::before {
						content: "";
						width: 1rem;
						height: 0.2rem;
						background-color: $gray3;
						position: absolute;
						top: 8px;
						@include bdown(md) {
							top: 6px;
						}
					}
					a {
						display: block;
						margin-left: 1.5rem;
						font-family: $sora;
						font-size: rem(15);
						font-weight: 700;
						color: $base;
					}
				}
			}
		}
	}
}

.connected {
	background-color: $blue;
	padding-top: 0;
	padding-bottom: 0;
	&-wrapper {
		display: flex;
		column-gap: 1rem;
		@include bdown(md) {
			display: block;
		}
		&-left {
			padding-top: 3rem;
			padding-bottom: 3rem;
			flex-basis: 50%;
			@include bdown(md) {
				max-width: 600px;
				margin: auto;
			}
			&-follow {
				display: flex;
				align-items: center;
				column-gap: 1rem;
				margin-top: 2rem;
				p {
					margin-bottom: 0;
				}
				@include bdown(nm) {
					display: block;
				}
				&-social {
					display: flex;
					align-items: center;
					column-gap: 1rem;
					&-icon {
						border: 6px solid $blue;
						outline: 1px solid $blue2;
						border-radius: 50%;
						width: 74px;
						height: 74px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: rem(30);
						color: $white;
						background-color: $green;
						@include transition;
						a {
							transition: none !important;
						}
						&:hover {
							color: $green;
							background-color: $white;
						}
						@include bdown(sm) {
							width: 65px;
							height: 65px;
						}
						@include bdown(xs) {
							width: 60px;
							height: 60px;
						}
					}
				}
			}
		}
		&-right {
			flex-basis: 50%;
			&-block {
				background-color: $base;
				padding: 1rem 2rem;
				max-width: 690px;
				margin-top: -2rem;
				margin-bottom: -2rem;
				@include bdown(md) {
					max-width: 600px;
					margin: auto;
				}
				@include bdown(sm) {
					padding-left: 1rem;
					padding-right: 1rem;
				}
				.block-header {
					position: relative;
					&::before {
						content: "";
						width: 2.7rem;
						height: 2px;
						background-color: $yellow;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
					h2 {
						color: $beige;
						padding-left: 3rem;
					}
				}
				.block-calendar {
					color: $whitealt;
					font-family: $montserrat;
					p {
						margin-bottom: 0;
						line-height: 1;
					}
					&-item {
						display: flex;
						column-gap: 1rem;
						margin-bottom: 1rem;
						@include bdown(xs) {
							display: block;
						}
						&-date {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							padding: 1rem;
							background-color: $green;
							min-width: 115px;
							max-height: 100px;
							@include bdown(sm) {
								min-width: 85px;
								max-height: 85px;
							}
							@include bdown(xs) {
								max-width: fit-content;
								max-height: 80px;
								margin-bottom: 1rem;
							}
							&-month {
								font-size: rem(22);
							}
							&-number {
								font-size: rem(58);
								white-space: nowrap;
							}
						}
						&-info {
							&-time {
								color: $yellow;
								font-size: rem(15);
								margin-bottom: 0.5rem;
							}
							&-text {
								font-size: rem(22);
							}
						}
					}
				}
			}
		}
	}
}

.quick-news {
	background-color: $beige;
	&-card {
		background-color: $yellow;
		&-image {
			position: relative;
			img {
				width: 100%;
				height: 100%;
			}
		}
		&-date {
			position: absolute;
			left: 2rem;
			bottom: 0;
			transform: translateY(30%);
			background-color: $green;
			color: $white;
			font-family: $montserrat;
			font-size: rem(19);
			font-weight: 500;
			// padding-left: 1rem;
			// padding-right: 1rem;
			padding: 0.4rem 1.6rem;
			p {
				margin-bottom: 0;
			}
		}
		&-info {
			padding: 2rem;
			&-header {
				font-family: $sora;
				font-size: rem(25);
				font-weight: 700;
			}
			&-text {
				color: $purplealt;
			}
		}
	}
}

.branches {
	background-color: rgba($color: $gray, $alpha: 0.56);
	&.pay-list {
		background-color: $lightgreen;
	}
	&.boards-list {
		background-color: $beige;
	}
	&.branch-services {
		background-color: rgba($color: $beige, $alpha: 0.64);
		.branches-flexbox {
			grid-template-columns: repeat(4, 1fr);
			@include bdown(nm) {
				grid-template-columns: repeat(3, 1fr);
			}
			@include bdown(sm) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				.branches-flexbox-item {
					margin-left: 0;
					margin-right: 0;
					margin-bottom: 0;
				}
			}
			@include bdown(xs) {
				grid-template-columns: repeat(1, 1fr);
			}
			&-item {
				&:hover {
					.branches-flexbox-item-info-bg {
						background-color: $purple;
					}
				}
			}
			&-item-image {
				&::before {
					content: "";
					background-color: $gold;
				}
			}
			&-item-info {
				font-size: rem(18);
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
				&-bg {
					background-color: $green;
				}
			}
		}
	}
	&.department-members {
		.branches-flexbox-item-info {
			padding-top: 0;
			padding-bottom: 0.5rem;
			font-size: rem(25);
			&-text {
				font-size: rem(19);
				font-weight: 400;
				color: $yellow;
			}
		}
	}
	&.comission-members {
		background-color: $beige;
		.branches-flexbox-item-info-text {
			font-size: rem(14);
		}
	}
	&-wrapper {
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
	}
	&-container {
		max-width: 1700px;
		margin: auto;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	&-flexbox {
		margin-top: 2rem;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 1rem;
		@include bdown(nm) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include bdown(sm) {
			display: block;
		}
		&-item {
			display: flex;
			max-width: 500px;
			&:hover {
				.branches-flexbox-item-info-bg {
					background-color: $green;
				}
			}
			@include bdown(sm) {
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 2rem;
			}
			&-image {
				position: relative;
				flex-basis: 28%;
				display: flex;
				height: rem(140);
				&::before {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 0.5rem;
					background-color: $green;
				}
				&::after {
					content: "";
					position: absolute;
					bottom: 0.5rem;
					top: 0;
					left: 0;
					right: 0;
					background: linear-gradient(
						to top,
						rgba($color: #84a331, $alpha: 0.5),
						rgba($color: $white, $alpha: 0)
					);
				}
				img {
					width: 100%;
					object-fit: cover;
				}
			}
			&-info {
				flex-basis: 72%;
				font-family: $sora;
				color: $white;
				font-size: rem(23);
				font-weight: 700;
				padding-top: 0.9rem;
				padding-bottom: 0.9rem;
				display: flex;
				flex-grow: 1;

				&-bg {
					background-color: $purple;
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding-left: 1rem;
					padding-right: 1rem;
					@include transition;
					p {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.meet {
	@include bdown(xs) {
		padding-bottom: 1rem;
	}
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		@include bdown(nm) {
			display: block;
		}
		&-item {
			flex-basis: 50%;
			display: flex;
			align-items: flex-start;
			@include bdown(nm) {
				margin-bottom: 1rem;
			}
			@include bdown(xs) {
				display: block;
				margin-bottom: 2rem;
			}
			&-image {
				position: relative;
				flex-basis: 50%;
				display: flex;
				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					top: 60%;
					left: 0;
					right: 0;
					background: linear-gradient(
						to top,
						rgba($color: #2c2735, $alpha: 1),
						rgba($color: #504660, $alpha: 0)
					);
				}

				@include bdown(nm) {
					flex-basis: 30%;
				}
				@include bdown(sm) {
					flex-basis: 40%;
				}
				img {
					width: 100%;
					object-fit: cover;
				}
			}
			&-info {
				flex-basis: 50%;
				padding-left: 1rem;
				@include bdown(nm) {
					flex-basis: 70%;
				}
				@include bdown(sm) {
					flex-basis: 60%;
				}
				@include bdown(xs) {
					padding: 0;
					margin-top: 1rem;
				}
				&-subheader {
					font-family: $sora;
					font-size: rem(32);
					color: $green;
					p {
						margin-bottom: 0;
					}
				}
				&-header {
					line-height: 1.1;
					h3 {
						margin-bottom: 1rem;
					}
				}
				.more-link {
					a {
						border-bottom: 2px solid
							rgba($color: $green, $alpha: 0.3);
					}
				}
			}
		}
	}
}

.boards {
	padding-top: 0;
	padding-bottom: 0;
	&-container {
		margin: auto;
		display: flex;
		@include bdown(md) {
			display: block;
		}
		&-image {
			flex-basis: 48%;
			display: flex;
			position: relative;
			@include bdown(md) {
				display: block;
			}
			&::after {
				content: "";
				position: absolute;
				bottom: 0rem;
				top: 0;
				left: 0;
				right: 0;
				background: linear-gradient(
					to top,
					rgba($color: #84a331, $alpha: 0.5),
					rgba($color: $white, $alpha: 0)
				);
			}
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		&-info {
			flex-basis: 52%;
			background-color: $green;
			padding: 2rem 1rem 2rem 3rem;
			color: $white;
			display: block;
			@include bdown(md) {
				padding: 2rem 2rem;
			}
			@include bdown(xs) {
				padding: 2rem 1rem;
			}
			&-wrapper {
				max-width: 670px;
			}
			.h1-style {
				p {
					margin-bottom: 0;
				}
			}
			.btn {
				@include transition;
			}
		}
	}
}

.department-faq {
	background-color: $lightgreen;
	&.services-faq {
		background-color: rgba($color: $beige, $alpha: 0.53);
	}
	&-item {
		&-question {
			cursor: pointer;
			&.active {
				.department-faq-item-header {
					color: $purple1;

					span {
						background-color: $green;
						&::before {
							content: "-";
						}
					}
				}
			}
		}
		&-answer {
			display: none;
		}
		&-header {
			font-family: $sora;
			font-size: rem(27);
			font-weight: 700;
			color: $green;
			position: relative;
			padding-right: 80px;
			padding-top: 1rem;
			padding-bottom: 1rem;
			display: flex;
			align-items: center;
			border-bottom: 3px solid $white;

			@include bdown(md) {
				padding-right: 55px;
			}
			span {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 63px;
				height: 57px;
				background-color: $purple1;
				@include bdown(md) {
					width: 45px;
					height: 40px;
				}
				&::before {
					content: "+";
					font-family: $lexend;
					font-weight: 400;
					color: $white;
					font-size: rem(58);
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -53%);
				}
			}
		}
		&-text {
			padding-top: 1rem;
			padding-bottom: 2rem;
			font-family: $lexend;
			color: $base;
			max-width: 900px;
			padding-left: 3rem;
			ul {
				::marker {
					color: $blue;
				}
			}
		}
	}
}

.some-link {
	background-color: rgba($color: $gray, $alpha: 0.56);
	&-flexbox {
		margin-top: 2rem;
		max-width: 1250px;
		margin-left: auto;
		margin-right: auto;
		ul {
			display: flex;
			justify-content: space-between;
			font-family: $sora;
			font-weight: 700;
			font-size: rem(16);
			color: $purplealt;
			@include bdown(sm) {
				flex-wrap: wrap;
				justify-content: space-evenly;
			}
			@include bdown(xs) {
				display: block;
			}

			li {
				display: flex;
				column-gap: 0.5rem;
				align-items: flex-end;
				@include bdown(sm) {
					margin-bottom: 2rem;
				}
			}
			i {
				color: $white;
				font-size: rem(32);
			}
		}
	}
	&-wrapper {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		ul {
			display: flex;
			justify-content: space-between;
			column-gap: 1rem;
			font-size: rem(16);
			font-family: $montserrat;
			font-weight: 600;
			@include bdown(sm) {
				display: block;
			}
			li {
				max-width: 320px;
				margin-left: 0;
				@include bdown(sm) {
					margin-bottom: 2rem;
				}

				a {
					display: flex;
					align-items: center;
					column-gap: 0.5rem;

					&::after {
						content: "\e902";
						font-family: $icomoon;
					}
					i {
						color: $white;
						font-size: rem(40);
					}
					p {
						margin-bottom: 0;
						flex-basis: 60%;
					}
				}
			}
		}
	}
}

.department-info {
	padding-top: 0;
	&.service-info {
		background-color: rgba($color: $beige, $alpha: 0.53);
		padding-bottom: 1rem;
		.department-info-flexbox-left {
			@include bdown(sm) {
				margin-bottom: 0;
			}
		}
	}
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		align-items: flex-start;
		justify-content: space-between;

		@include bdown(sm) {
			flex-direction: column;
		}
		&-left {
			margin-top: 3rem;
			flex-basis: 70%;
			@include bdown(sm) {
				margin-bottom: 2rem;
				order: 1;
			}
		}
		&-right {
			flex-basis: 30%;
			background-color: $blue;
			padding: 2rem 3rem;
			max-width: 450px;
			@include bdown(sm) {
				margin-left: auto;
				max-width: 370px;
			}
			@include bdown(xs) {
				margin-right: auto;
			}
			p {
				margin-bottom: 0;
			}
			&-subheader {
				color: $white;
			}
			&-header {
				font-family: $sora;
				font-size: rem(25);
				font-weight: 700;
				margin-bottom: 0.5rem;
			}
			&-adress {
				font-size: rem(16);
				margin-bottom: 1rem;
			}
			&-phone,
			&-email,
			&-hours {
				display: flex;
				align-items: center;
				column-gap: 0.5rem;
				font-size: rem(20);
				font-weight: 500;
				color: $purplealt;
				margin-bottom: 0.5rem;

				i {
					font-size: rem(24);
					color: $white;
				}
			}
			&-hours {
				a {
					text-decoration: none !important;
					border-bottom: 2px solid rgba($color: $base, $alpha: 0.3);
				}
				&::after {
					content: "\e902";
					font-family: $icomoon;
					display: inline;
					vertical-align: middle;

					color: $white;
				}
			}
			&-social {
				margin-top: 2rem;
				display: flex;
				column-gap: 0.5rem;
			}
		}
	}
}

.featured {
	background-color: $lightgreen;
	@include bdown(xs) {
		padding-bottom: 1rem;
	}
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		@include bdown(nm) {
			display: block;
		}
		&-item {
			flex-basis: 50%;
			display: flex;
			align-items: flex-start;
			@include bdown(nm) {
				margin-bottom: 1rem;
			}
			@include bdown(xs) {
				display: block;
				margin-bottom: 2rem;
			}
			&-image {
				position: relative;
				flex-basis: 55%;
				display: flex;
				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 0.75rem;
					background-color: $purple;
				}

				@include bdown(nm) {
					flex-basis: 30%;
				}
				@include bdown(sm) {
					flex-basis: 40%;
				}
				img {
					width: 100%;
					object-fit: cover;
				}
			}
			&-info {
				flex-basis: 45%;
				padding-left: 1rem;
				@include bdown(nm) {
					flex-basis: 70%;
				}
				@include bdown(sm) {
					flex-basis: 60%;
				}
				@include bdown(xs) {
					padding: 0;
					margin-top: 1rem;
				}
				&-subheader {
					font-family: $sora;
					font-size: rem(32);
					color: $green;
					p {
						margin-bottom: 0;
					}
				}
				&-header {
					h3 {
						margin-bottom: 0.5rem;
					}
				}
				.more-link {
					a {
						border-bottom: none;
					}
				}
			}
		}
	}
}

.link-center {
	background-color: rgba($color: $beige, $alpha: 0.64);
	&.services-links {
		background-color: rgba($color: $gray4, $alpha: 0.64);
	}
	&.trending {
		.trending-container {
			max-width: 1700px;
			margin: auto;
			padding-left: 1rem;
			padding-right: 1rem;
			.green-base-header {
				margin-left: 2rem;
			}
		}
		.link-center-wrapper {
			grid-template-columns: repeat(3, 1fr);
			@include bdown(md) {
				grid-template-columns: repeat(2, 1fr);
			}
			@include bdown(sm) {
				display: block;
			}
			&-item {
				max-width: 455px;
				color: $purplealt;
				padding: 0.5rem 1rem;
				min-height: 4.8rem;
				text-align: center;
				&:hover {
					color: $white;
				}
				@include bdown(sm) {
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 1rem;
				}
			}
		}
	}
	&-wrapper {
		margin-top: 2rem;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1rem;
		@include bdown(nm) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include bdown(sm) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include bdown(xs) {
			display: block;
		}
		&-item {
			font-family: $sora;
			font-size: rem(21);
			font-weight: 700;
			color: $gray2;
			border: 3px solid $blue;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 330px;
			padding: 0.6rem 1rem;
			text-align: center;
			@include transition;
			a {
				transition: none !important;
				display: flex;
				align-items: center;
				column-gap: 0.2rem;
			}
			&:hover {
				color: $white;
				background-color: $green;
			}
			@include bdown(xs) {
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 1rem;
			}
		}
	}
}

.upcoming {
	background-color: $lightgreen;
	&-header-wrapper {
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		.green-base-header {
			h2 {
				margin-bottom: 0;
			}
		}
		.more-link {
			color: $green;
			a {
				border-bottom-color: rgba($color: $green, $alpha: 0.3);
			}
			@include bdown(md) {
				text-align: end;
				margin-bottom: 1rem;
			}
		}
		@include bdown(md) {
			flex-direction: column-reverse;
		}
	}

	&-container {
		margin: auto;
		padding-left: 3.5rem;
		padding-right: 3.5rem;
		margin-bottom: 2rem;
		&-mini {
			max-width: 1400px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		justify-content: center;
		align-items: flex-start;
		@include bdown(nm) {
			display: block;
		}

		&-part {
			max-width: 850px;
			display: flex;
			align-items: flex-start;
			flex-basis: 50%;
			padding-left: 1rem;
			p {
				margin-bottom: 0;
			}

			@include bdown(nm) {
				display: flex;
			}
			@include bdown(sm) {
				display: block;
				padding-left: 0;
			}
			&-image {
				position: relative;
				flex-basis: 40%;
				display: flex;
				&::before {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 0.5rem;
					background-color: $green;
				}
				&::after {
					content: "";
					position: absolute;
					bottom: 0.5rem;
					top: 0;
					left: 0;
					right: 0;
					background: linear-gradient(
						to top,
						rgba($color: #84a331, $alpha: 0.5),
						rgba($color: $white, $alpha: 0)
					);
				}
				img {
					width: 100%;
				}
				@include bdown(md) {
					max-width: 75%;
				}
				@include bdown(sm) {
					max-width: 100%;
				}
			}
			&-date {
				height: rem(110);
				width: rem(125);
				background-color: $green;
				color: $white;
				font-family: $sora;
				font-size: rem(14);
				font-weight: 700;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				padding: 0.5rem 0.3rem;
			}
			&-info {
				flex-basis: 60%;
				display: flex;
				@include bdown(md) {
					max-width: 75%;
				}
				@include bdown(sm) {
					max-width: 100%;
				}
				&-padding {
					padding-top: 1rem;
					@include bdown(nm) {
						padding-top: 0;
					}
					.more-link {
						padding-left: 1rem;
						a,
						div {
							border-bottom: 2px solid $green;
							display: inline;
						}
					}
				}
				&-bg {
					background-color: $purple;
					padding: 0.4rem 0.4rem 0.5rem 1rem;
					margin-bottom: 0.5rem;
					min-height: rem(82);
					display: flex;
					flex-direction: column;
					justify-content: center;
					&-header {
						font-family: $sora;
						font-size: rem(25);
						font-weight: 700;
						color: $white;
					}
					&-subheader {
						font-family: $sora;
						font-size: rem(19);
						font-weight: 400;
						color: $yellow;
					}
					&-text {
						padding-left: 1rem;
						font-size: rem(18);
						margin-bottom: 1rem;
						@include bdown(xs) {
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}

.event-list {
	background-color: rgba($color: $beige, $alpha: 0.64);
	&-wrapper {
		p {
			margin-bottom: 0;
		}
	}
	&-header {
		display: grid;
		gap: 1rem;
		grid-template-columns: 3fr 1fr 2fr 1fr;
		color: $green;
		font-size: rem(31);
		font-family: $sora;
		font-weight: 700;
		margin-bottom: 0.5rem;
		padding-left: 1.5rem;
	}
	&-text {
		display: grid;
		gap: 1rem;
		grid-template-columns: 3fr 1fr 2fr 1fr;
		font-size: rem(18);
		font-weight: 400;
		color: $purplealt;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 1.5rem;
		margin-bottom: 0.5rem;
		border-top: 3px solid $gray4;
		border-bottom: 3px solid $gray4;
		position: relative;
		&::before {
			content: "\e902";
			font-family: $icomoon;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: transparent;
			@include transition;
		}
		&:hover {
			color: $green;
			&::before {
				color: $green;
			}
		}
		@include bdown(sm) {
			grid-template-columns: 1fr 1fr;
			row-gap: 0.5rem;
		}
		&-info {
			@include bdown(sm) {
				grid-row: 1/4;
				align-self: center;
			}
		}
	}
}

.news {
	background-color: $lightgreen;
	&-header-wrapper {
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		margin-bottom: 3rem;
		@include bdown(md) {
			flex-direction: column-reverse;
		}
		p {
			margin-bottom: 0;
		}
		.green-base-header {
			h2 {
				margin-bottom: 0;
			}
		}
		.active-year {
			background-color: $green;
			color: $white;
		}
		&-item {
			display: flex;
			column-gap: 1rem;
			flex-basis: 50%;
			justify-content: flex-end;
			&-year {
				flex-grow: 1;
				border: 3px solid $blue;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.5rem;
				max-width: rem(188);
				color: $purplealt;
				&:hover {
					background-color: $green;
					color: $white;
				}
				@include bdown(md) {
					margin-bottom: 1rem;
				}
			}
		}
	}
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		justify-content: space-between;
		align-items: flex-start;
		@include bdown(nm) {
			display: block;
		}
		&-part {
			max-width: 1450px;
			display: flex;
			align-items: flex-start;
			padding-left: rem(111);
			margin-bottom: 2rem;

			@include bdown(md) {
				display: block;
			}
			@include bdown(xs) {
				padding-left: 0;
				padding-top: 3rem;
			}
			&-wrapper {
				flex-grow: 1;
				flex-basis: 34%;
			}
			&-image {
				position: relative;
				flex-basis: 34%;
				display: flex;
				p {
					margin-bottom: 0;
				}
				&::before {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 0.5rem;
					background-color: $green;
				}
				img {
					width: 100%;
				}
			}
			&-date {
				position: absolute;
				height: rem(96);
				width: rem(110);
				top: 0;
				left: rem(-111);
				background-color: $green;
				color: $white;
				font-family: $sora;
				font-size: rem(15);
				font-weight: 700;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				padding: 0.5rem;
				@include bdown(xs) {
					right: 0;
					top: rem(-96);
					left: auto;
				}
			}
			&-info {
				flex-basis: 66%;
				display: flex;
				flex-direction: column;
				padding: 1rem 1rem 1rem 2rem;
				@include bdown(sm) {
					padding-left: 1rem;
				}
				@include bdown(xs) {
					padding-left: 0;
					padding-right: 0;
				}
				.more-link {
					a {
						border-bottom-color: $green;
					}
				}

				&-header {
					font-family: $sora;
					font-size: rem(25);
					font-weight: 700;
					color: $purple;
				}

				&-text {
					font-size: rem(18);
				}
			}
		}
	}
	&-bottom-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		column-gap: 1rem;
		margin-bottom: 3rem;
		@include bdown(xs) {
			flex-direction: column;
			align-items: flex-end;
		}
		p {
			margin-bottom: 0;
		}

		.active-year {
			background-color: $green;
			color: $white;
		}
		&-archive {
			font-family: $sora;
			font-size: rem(25);
			font-weight: 700;
			color: $purple;
			@include bdown(xs) {
				margin-bottom: 1rem;
			}
		}
		&-item {
			display: flex;
			column-gap: 1rem;
			flex-basis: 50%;
			justify-content: flex-end;
			&-year {
				flex-grow: 1;
				border: 3px solid $blue;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.5rem;
				max-width: rem(188);
				color: $purplealt;
				&:hover {
					background-color: $green;
					color: $white;
				}
				@include bdown(md) {
					margin-bottom: 1rem;
				}
			}
		}
	}
}

.city-news-pagination {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	justify-content: center;
	font-family: $sora;
	font-weight: 700;
	color: $purplealt;
	&-item {
		border: 3px solid $blue;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 0.5rem;
		min-width: 2rem;
		&:hover {
			background-color: $green;
			color: $white;
		}
		&.active {
			background-color: $green;
			color: $white;
		}
	}
	&-arrow-prev {
		position: relative;
		&::before {
			content: "\e901";
			display: inline;
			font-family: icomoon !important;
			margin-right: 0.5rem;
			vertical-align: middle;
		}
	}
	&-arrow-next {
		position: relative;
		&::before {
			content: "\e902";
			display: inline;
			font-family: icomoon !important;
			margin-left: 0.5rem;
			vertical-align: middle;
		}
	}
}

.inside-news {
	background-color: $lightgreen;
	&-wrapper {
		max-width: 970px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		padding-left: rem(120);
		padding-top: rem(30);
		margin-bottom: 2rem;
		margin-top: 1rem;
		@include bdown(xs) {
			padding-left: 0;
		}
	}
	&-date {
		position: absolute;
		height: rem(96);
		width: rem(110);
		top: 0;
		left: 0;
		background-color: $green;
		color: $white;
		font-family: $sora;
		font-size: rem(15);
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0.5rem;
		p {
			margin-bottom: 0;
		}
		@include bdown(xs) {
			right: 0;
			top: rem(-96);
			left: auto;
		}
	}
	&-bottom-links {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.department-info-flexbox-right-social {
			margin-top: 0;
			align-items: center;
			p {
				margin-bottom: 0;
			}
			@include bdown(xs) {
				justify-content: flex-end;
				margin-bottom: 1rem;
			}
		}
		@include bdown(xs) {
			flex-direction: column-reverse;
			align-items: stretch;
		}
	}
}

.community-events {
	background-color: $lightgreen;
	&-container {
		padding-left: 1rem;
		padding-right: 1rem;
		max-width: 1700px;
		margin-left: auto;
		margin-right: auto;
		.upcoming-flexbox {
			@include bdown(sm) {
				display: block;
			}
			&-part {
				@include bdown(nm) {
					margin-bottom: 2rem;
				}

				@include bdown(sm) {
					margin-bottom: 4rem;
				}
			}
		}
	}
}

.inside-event {
	background-color: $lightgreen;
	.more-link {
		text-align: end;
		color: $green;
		a {
			border-bottom-color: rgba($color: $green, $alpha: 0.3);
		}
	}
	&-wrapper {
		max-width: 1400px;
		margin-left: auto;
	}
	&-part {
		display: flex;
		align-items: flex-start;
		
		p {
			margin-bottom: 0;
		}
		@include bdown(nm) {
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1rem;
		}
		@include bdown(sm) {
			display: block;
			
		}
		
		&-image {
			position: relative;
			flex-basis: 50%;
			display: flex;
			flex-direction: column;
			
			img {
				width: 100%;
			}
			&-social {
				display: flex;
				column-gap: 0.5rem;
				align-items: center;
				margin-bottom: 1rem;
			}
			&-more {
				font-size: rem(18);
				margin-bottom: 1rem;
				margin-top: 1rem;
			}
		}
		&-date {
			
			height: rem(179);
			width: rem(203);
			background-color: $green;
			color: $white;
			font-family: $sora;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0.5rem;
			@include bdown(sm) {
				height: rem(159);
				width: rem(183);
				
			}
			
			&-mounth {
				font-weight: 700;
				font-size: rem(20);
				@include bdown(md) {
					font-size: rem(18);
				}
			}
			&-time {
				font-size: rem(23);
				font-weight: 400;
			}
		}
		&-info {
			flex-basis: 50%;
			display: flex;
			&-padding {
				padding-top: 1rem;
				@include bdown(sm) {
					padding-top: 0;
				}
				.more-link {
					padding-left: 1rem;
					a {
						border-bottom-color: $green;
					}
				}
			}
			&-bg {
				background-color: $purple;
				padding: 0.4rem 0.4rem 0.5rem 1rem;
				margin-bottom: 1rem;
				min-height: rem(82);
				display: flex;
				flex-direction: column;
				justify-content: center;
				&-header {
					font-family: $sora;
					font-size: rem(39);
					font-weight: 700;
					color: $white;
				}
				&-subheader {
					font-family: $sora;
					font-size: rem(30);
					font-weight: 400;
					color: $yellow;
				}
			}
			&-text {
				padding-left: 1rem;
				font-size: rem(18);
				@include bdown(xs) {
					padding-left: 0;
				}
			}
			&-social {
				margin-top: 2rem;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				column-gap: 0.5rem;
			}
		}
	}
}

.submit-event {
	background-color: $lightgreen;
	.more-link {
		text-align: end;
		color: $green;
		a {
			border-bottom-color: rgba($color: $green, $alpha: 0.3);
		}
		@include bdown(nm) {
			margin-bottom: 2rem;
		}
	}
	&-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 2rem;
		@include bdown(nm) {
			flex-direction: column-reverse;
		}
	}
	&-text {
		max-width: 1350px;
		margin-left: auto;
		margin-right: auto;
	}
}

.boards-policy {
	background-color: rgba($color: #dfe5c1, $alpha: 0.06);
}

.board-project {
	&.resourse-name {
		background-color: rgba($color: $beige, $alpha: 0.53);
		.h1-style {
			p {
				margin-bottom: 1rem;
			}
		}
		h5 {
			margin-bottom: 0;
		}
		.board-project-flexbox-text {
			p {
				font-weight: 500;
				font-size: rem(20);
			}
		}
	}
	&.bg-beige {
		background-color: rgba($color: $beige, $alpha: 0.53);
	}
	&-flexbox {
		display: flex;
		column-gap: 2rem;
		align-items: flex-start;
		justify-content: space-between;

		@include bdown(sm) {
			flex-direction: column-reverse;
			row-gap: 1rem;
		}
		&-text {
			flex-basis: 66%;
		}
		&-image {
			display: flex;
			flex-basis: 34%;
			@include bdown(sm) {
				width: 100%;
				img {
					width: 100%;
				}
			}
		}
	}
}

.council-promo {
	background-color: rgba($color: $beige, $alpha: 0.53);
	&-item {
		display: flex;
		align-items: flex-start;
		column-gap: 1rem;
		@include bdown(nm) {
			margin-bottom: 1rem;
		}
		@include bdown(md) {
			column-gap: 0;
		}
		@include bdown(sm) {
			display: block;
		}
		&-image {
			position: relative;
			flex-basis: 22%;
			display: flex;
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				top: 60%;
				left: 0;
				right: 0;
				background: linear-gradient(
					to top,
					rgba($color: #2c2735, $alpha: 1),
					rgba($color: #504660, $alpha: 0)
				);
			}

			@include bdown(nm) {
				flex-basis: 30%;
			}
			@include bdown(sm) {
				flex-basis: 40%;
			}
			img {
				width: 100%;
				object-fit: cover;
			}
		}
		&-info {
			margin-left: 3rem;
			flex-basis: 70%;
			padding: 1rem;
			@include bdown(nm) {
				flex-basis: 78%;
			}
			@include bdown(sm) {
				flex-basis: 60%;
				padding-left: 0;
				padding-right: 0;
			}
			@include bdown(xs) {
				margin-left: 0;
			}
			&-subheader {
				position: relative;
				font-family: $sora;
				font-size: rem(32);
				color: $green;
				p {
					margin-bottom: 0;
				}
				&::before {
					content: "";
					width: 2.9rem;
					height: 0.3rem;
					background-color: $green;
					position: absolute;
					left: -3.5rem;
					transform: translateY(-50%);
					top: 25px;
					transform: translateY(-50%);
					@include bdown(md) {
						top: 19px;
					}
					@include bdown(xs) {
						display: none;
					}
				}
			}
			&-header {
				line-height: 1.1;
				h3 {
					margin-bottom: 1rem;
				}
			}
			.more-link {
				a {
					border-bottom: 2px solid rgba($color: $green, $alpha: 0.3);
				}
			}
		}
	}
}

.members {
	background-color: $blue;
	padding-bottom: 5rem;
	&-container {
		max-width: 1800px;
		margin-left: auto;
		padding-left: 1rem;
		@include bdown(sm) {
			padding-right: 1rem;
		}
	}
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		@include bdown(sm) {
			display: block;
		}
		&-left {
			flex-basis: 45%;
		}
		&-right {
			max-width: 55%;

			@include bdown(sm) {
				max-width: 100%;
				margin-bottom: 3rem;
			}
		}
	}
	&-subheader {
		color: $white;
		font-family: $sora;
		font-size: rem(30);
		padding-left: 3.5rem;
		p {
			margin-bottom: 0;
		}
	}
	&-text {
		font-weight: 500;
	}
}

.council-meetings {
	background-color: $beige;
	&-flexbox {
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		@include bdown(md) {
			display: block;
		}
		&-left {
			flex-basis: 50%;
			&-text {
				font-family: $montserrat;
				color: $green;
			}
			@include bdown(md) {
				margin-bottom: 2rem;
			}
		}
		&-right {
			flex-basis: 45%;
		}
	}
	&-events {
		margin-top: 3rem;
		display: flex;
		column-gap: 2rem;
		align-items: flex-start;
		@include bdown(md) {
			column-gap: 3rem;
		}
		@include bdown(xs) {
			display: block;
		}
		&-flexbox {
			max-width: 290px;
			display: flex;
			column-gap: 1rem;
			align-items: flex-start;
			margin-bottom: 2rem;
			&::after {
				content: "\e902";
				font-family: $icomoon;
			}
			i {
				font-size: rem(40);
				color: $green;
			}
			&-header {
				font-size: rem(16);
				font-family: $montserrat;
				font-weight: 600;
				color: $base;
				p {
					margin-bottom: 0;
				}
			}
			&-text {
				font-size: rem(14);
				font-family: $montserrat;
				font-weight: 500;
				color: $darkpurple;
			}
		}
	}
}

.accessibility {
	background-color: rgba($color: #dfe5c1, $alpha: 0.06);
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		justify-content: space-between;
		align-items: flex-start;
		@include bdown(sm) {
			flex-direction: column-reverse;
			align-items: center;
		}
		&-left {
			flex-basis: 50%;
		}
		&-right {
			margin-bottom: 1rem;
		}
	}
}

.mayor-inside {
	background-color: rgba($color: $beige, $alpha: 0.53);
	.green-header-h3 {
		margin-bottom: 0;
		&::before {
			content: "";
			width: 2.8rem;
			left: -0.5rem;
		}
		h3 {
			font-size: rem(32);
			font-weight: 400;
			padding-left: 3rem;
		}
	}
	.inside-event-part-info-social {
		justify-content: flex-start;
		&.council-member {
			p {
				margin-bottom: 0;
				margin-right: 2rem;
				font-family: $sora;
				font-size: rem(26);
				color: $green;
			}
		}
	}
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		align-items: flex-start;
		justify-content: space-between;
		@include bdown(sm) {
			display: block;
		}
		&-left {
			position: relative;
			flex-basis: 28%;
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 1rem;
				background-color: $purple;
			}
			&::after {
				content: "";
				position: absolute;
				bottom: 1rem;
				top: 80%;
				left: 0;
				right: 0;
				background: linear-gradient(
					to top,
					rgba($color: #84a331, $alpha: 0.9),
					rgba($color: $white, $alpha: 0)
				);
			}
			img {
				width: 100%;
			}
			@include bdown(md) {
				flex-basis: 35%;
			}
			@include bdown(sm) {
				max-width: 500px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 2rem;
			}
		}
		&-right {
			flex-basis: 65%;
		}
	}
}

.permit {
	background-color: $lightgreen;
	.btn-purple-inverted {
		&:hover {
			border: 1px solid $green;

			background-color: $green;
			color: $white;
			&::after {
				color: $white;
			}
		}
	}
}

.openings {
	background-color: rgba($color: $beige, $alpha: 0.64);
	&-header {
		font-family: $sora;
		font-size: rem(31);
		font-weight: 700;
		color: $green;
		display: grid;
		grid-template-columns: 3fr 2fr;
		border-bottom: 3px solid $gray4;
		@include bdown(xs) {
			display: none;
		}
	}
	&-row {
		font-size: rem(25);
		color: $purple;
		display: grid;
		grid-template-columns: 3fr 2fr;
		border-bottom: 3px solid $gray4;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		p {
			margin-bottom: 0;
		}
		&:hover {
			color: $green;
		}
		@include bdown(xs) {
			display: block;
		}
	}
}

.accoutant {
	background-color: $lightgreen;
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		@include bdown(sm) {
			display: block;
		}
		&-left {
			flex-basis: 50%;
		}
		&-right {
			flex-basis: 50%;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			@include bdown(sm) {
				display: block;
				margin-top: 2rem;
			}
		}
	}
	&-row {
		display: grid;
		grid-template-columns: 2fr 3fr;
		column-gap: 1rem;
		@include bdown(xs) {
			display: block;
		}
		p {
			margin-bottom: 0.5rem;
		}
		&-data {
			font-size: rem(17);
			font-weight: 500;
			color: rgba($color: $green, $alpha: 0.7);
			justify-self: flex-end;
		}
		&-info {
			color: rgba($color: $darkpurple, $alpha: 0.7);
			font-size: rem(20);
			font-weight: 500;
		}
	}
}

.job-details {
	background-color: rgba($color: $beige, $alpha: 0.64);
}

.resources {
	&-wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 1rem;
		@include bdown(nm) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include bdown(sm) {
			grid-template-columns: 1fr;
			justify-items: center;
		}
	}
	&-item {
		display: block;
		background-color: $lightgreen;
		padding: 1rem 3.5rem 1rem 2rem;
		max-width: 520px;
		position: relative;
		@include bdown(xs) {
			padding-left: 1rem;
		}
		&::before {
			content: "";
			position: absolute;
			width: 3.2rem;
			top: 0;
			bottom: 0;
			right: 0;
			background-color: $yellow;
			@include transition;
		}
		&::after {
			content: "\e902";
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			right: 0.8rem;
			color: $gold;
			font-family: $icomoon;
			font-size: rem(28);
			opacity: 0;
			@include transition;
		}
		&:hover {
			background-color: $gold;
			&::before {
				background-color: $green;
			}
			&::after {
				opacity: 1;
			}
		}

		&-header {
			font-family: $sora;
			font-size: rem(31);
			font-weight: 700;
			color: $green;
			position: relative;
			padding-bottom: 1rem;
			line-height: 1.1;
			&::after {
				content: "";
				width: 2.9rem;
				height: 0.3rem;
				background-color: $green;
				position: absolute;
				transform: translateY(-50%);
				bottom: 0;

				@include bdown(md) {
					width: 2.5rem;
				}
			}
			p {
				margin-bottom: 0;
			}
		}
		&-text {
			margin-top: 1rem;
			font-size: rem(17);
			color: $gray2;
			p {
				margin-bottom: 0;
			}
		}
	}
}

.issue {
	background-color: $lightgreen;
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		justify-content: space-between;
		@include bdown(md) {
			flex-direction: column-reverse;
		}
		&-left {
			flex-basis: 70%;
		}
		&-right {
			flex-basis: 30%;
			display: flex;
			justify-content: center;
			img {
				object-fit: cover;
			}
			@include bdown(md) {
				margin-bottom: 2rem;
			}
		}
	}
}

.contacts {
	background-color: rgba($color: $beige, $alpha: 0.64);
	.link-center-wrapper {
		grid-template-columns: repeat(3, 1fr);
		@include bdown(md) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include bdown(sm) {
			display: block;
		}
		&-item {
			max-width: 329px;
			color: $purplealt;
			padding: 0.2rem 0.5rem;
			min-height: 3.8rem;
			text-align: center;
			&:hover {
				color: $white;
			}
			&.active {
				background-color: $green;
				color: $white;
			}
			@include bdown(sm) {
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 1rem;
			}
		}
	}
	&-wrapper {
		p {
			margin-bottom: 0;
		}
	}
	&-header {
		display: grid;
		gap: 2rem;
		grid-template-columns: 2fr 3fr 2fr 2fr;
		color: $green;
		font-size: rem(31);
		font-family: $sora;
		font-weight: 700;
		margin-bottom: 0.5rem;
		padding-left: 1.5rem;
	}
	&-text {
		display: grid;
		gap: 2rem;
		grid-template-columns: 2fr 3fr 2fr 2fr;
		font-size: rem(18);
		font-weight: 400;
		color: $purplealt;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 1.5rem;
		margin-bottom: 0.5rem;
		border-top: 3px solid $gray4;
		border-bottom: 3px solid $gray4;
		position: relative;

		@include bdown(sm) {
			grid-template-columns: 1fr 2fr;
			row-gap: 0.5rem;
			column-gap: 1rem;
		}
		&-pos {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		&-info {
			display: flex;
			align-items: center;
			font-weight: 700;
			@include bdown(sm) {
				grid-row: 1/4;
				align-self: center;
			}
			&:hover {
				color: $green;
			}
		}
	}
}

.report {
	background-color: rgba($color: $beige, $alpha: 0.64);
}
.traffic-source-link {
	display: inline-block;
	@include bup(md){
		img {
			max-width: 250px;
		}
	}
	@include bdown(xs){
		display: block;
		text-align: center;
		img {
			width: 100%;
		}
	}
}

.wysiwyg-ul-green {
	ul {
		margin-left: 2rem;
		@include bdown(xs) {
			margin-left: 0;
		}
		::marker {
			color: $green;
		}
	}
	a {
		color: $green;
		text-decoration: none;
	}
}

.wysiwyg-ul-green1 {
	ul {
		::marker {
			color: $green;
		}
	}
	a {
		color: $green;
		text-decoration: none;
	}
}

.wysiwyg-job-inside {
	ul {
		margin-left: 2rem;
		@include bdown(xs) {
			margin-left: 0;
		}
		li {
			margin-bottom: 1rem;
		}
		::marker {
			color: $green;
		}
	}
	a {
		color: $green;
		text-decoration: none;
	}
	p {
		color: $purplealt;
	}
	h5 {
		font-weight: 700;
		color: $green;
		@include letter-spacing(0);
	}
}

.wysiwyg-services {
	ul {
		li {
			margin-bottom: 1rem;
		}
		::marker {
			color: $green;
		}
	}
	a {
		color: $green;
	}

	p {
		margin-bottom: 0;
	}
	h5 {
		font-weight: 700;
		color: $green;
		@include letter-spacing(0);
	}
}

.wysiwyg-montserrat {
	font-family: $montserrat;
	ul {
		::marker {
			color: $green;
		}
	}
	a {
		color: $green;
		text-decoration: none;
	}
}

.wysiwyg-text-18 {
	font-size: rem(18);
	p {
		margin-bottom: 1rem;
	}
	a {
		color: $green;
		text-decoration: none;
	}
	h6 {
		font-size: rem(18);
		font-family: $lexend;
		color: $green;
	}
	ul {
		margin-left: 2rem;
		@include bdown(xs) {
			margin-left: 0;
		}
		::marker {
			color: $green;
		}
	}
}
