.slider-wrapper {
	position: relative !important;
	&.init {
		min-height: 200px;
		max-height: 30vh;
		.slick-slider {
			opacity: 0;
		}
	}
	.preloader-overlay {
		position: absolute;
		background: transparent;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 5;

		.preloader {
			position: absolute !important;
			top: 50%;
			left: 50%;
			width: 6rem;
			height: 6rem;
			margin-top: -3rem;
			margin-left: -3rem;
			background-image: url(../../images/half-slider/new-loading.gif);
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	.prev-arrow,
	.next-arrow {
		position: absolute;
		text-decoration: none;
		transition: 300ms ease-in-out;
		z-index: 5;
	}

	.gray-prev-arrow {
		background-color: $gray;
		width: 65px;
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 132px;
		bottom: 1rem;
		&.icon-arrow-left:before {
			content: "\e901";
			font-family: $icomoon;
			color: $white;
			font-size: rem(20);
		}
		&:hover {
			filter: brightness(120%);
		}

		@include bdown(sm) {
			width: 55px;
			height: 55px;
			right: 112px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 50px;
			right: 93px;
		}
	}

	.gray-next-arrow {
		background-color: $gray;
		width: 65px;
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 65px;
		bottom: 1rem;
		&.icon-arrow-right:before {
			content: "\e902";
			font-family: $icomoon;
			color: $white;
			font-size: rem(20);
		}

		&:hover {
			filter: brightness(120%);
		}

		@include bdown(sm) {
			width: 55px;
			height: 55px;
			right: 55px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 50px;
			right: 40px;
		}
	}
	.green-prev-arrow {
		background-color: $green;
		width: 65px;
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: center;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		&.icon-arrow-left:before {
			content: "\e901";
			font-family: $icomoon;
			color: $white;
			font-size: rem(20);
		}
		&:hover {
			filter: brightness(120%);
		}

		@include bdown(sm) {
			width: 55px;
			height: 55px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 50px;
		}
	}

	.green-next-arrow {
		background-color: $green;
		width: 65px;
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		&.icon-arrow-right:before {
			content: "\e902";
			font-family: $icomoon;
			color: $white;
			font-size: rem(20);
		}

		&:hover {
			filter: brightness(120%);
		}

		@include bdown(sm) {
			width: 55px;
			height: 55px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 50px;
		}
	}
}

.fw-slider-large,
.half-slider-large {
	.slick-slide {
		min-height: 80vh;
	}
}

.fw-slider-middle,
.half-slider-middle {
	.slick-slide {
		min-height: 60vh;
	}
}

.fw-slider-small,
.half-slider-small {
	.slick-slide {
		min-height: 40vh;
	}
}

.half-slider-wrapper {
	max-width: 50%;
	flex-grow: 1;
	flex-basis: 50%;
	@include bdown(md) {
		max-width: 100%;
		display: flex;
		flex-basis: 100%;
	}

	.half-slider {
		height: 100%;
		width: 100%;

		&-slide {
			height: inherit;
			width: inherit;

			img {
				height: inherit;
				width: inherit;
				object-fit: cover;
			}
		}

		.slick-list {
			height: inherit;
			width: inherit;

			.slick-track {
				height: inherit;
				width: inherit;
			}
		}
	}
}

.fw-slider {
	overflow: hidden;

	&-slide {
		padding-top: 2rem;
		padding-bottom: 4rem;
	}
	.slick-track {
		display: flex;

		.slick-slide {
			position: relative;
			display: flex;
			height: inherit;
			padding-left: 3rem;
			padding-right: 3rem;
			@include bdown(xs) {
				padding-left: 2rem;
				padding-right: 2rem;
			}
			img {
				object-fit: cover;
				width: 100%;
			}
		}
	}
}

.upcoming-slider {
	.slick-track {
		display: flex;
		column-gap: 1rem;
		.upcoming-flexbox-part {
			flex-basis: auto;
			margin: auto;
		}
		@include bdown(sm) {
			display: block;
		}
	}
	.gray-prev-arrow {
		left: 0;
		top: 50%;
		transform: translateY(-50%);

		@include bdown(sm) {
			left: 0;
		}

		@include bdown(xs) {
			left: 0;
		}
	}

	.gray-next-arrow {
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		@include bdown(sm) {
			right: 0;
		}

		@include bdown(xs) {
			right: 0;
		}
	}
}

.members-slider {
	padding-left: 2rem;
	@include bdown(sm) {
		padding-left: 0;
	}
	.slick-track {
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
	}

	&-slide {
		max-width: 345px;

		&-card {
			position: relative;
			&-image {
				display: flex;
				position: relative;
				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					top: 60%;
					left: 0;
					right: 0;
					background: linear-gradient(
						to top,
						rgba($color: #2c2735, $alpha: 1),
						rgba($color: #504660, $alpha: 0)
					);
				}
				img {
					width: 100%;
					height: 100%;
				}
			}
			&-info {
				font-family: $montserrat;
				position: absolute;
				bottom: 10%;
				left: 10%;

				p {
					margin-bottom: 0;
				}
				&-name {
					font-size: rem(24);
					color: $green;
					font-weight: 700;
				}
				&-position {
					font-size: rem(15);
					color: $white;
					font-weight: 500;
				}
			}
		}
	}

	.prev-arrow,
	.next-arrow {
		top: 105%;
		transform: translateY(0);
	}
	.next-arrow {
		left: 70px;
		@include bdown(sm) {
			left: 60px;
		}
		@include bdown(xs) {
			left: 55px;
		}
	}
}
