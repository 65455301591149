$black: #000000;
$base: #4E475E;
$blackalt: #2b2b33;
$purplealt: #504660;
$purple: #847596;
$purple1: #988096;
$darkpurple: #221026;
$gray: #A7AEC2;
$darkgray: #434343;
$gray1: #939DBA;
$gray2: #6E7380;
$gray3: #CED1DB;
$gray4: #EBEBEB;
$blue: #CDD1DC;
$blue2 : #8AB5B0;
$white: #fff;
$whitealt: #E9EBF0;
$beige: #FAF9EE;
$lightgreen: #F2F5E5;
$green: #9ABF48;
$greenalt: #A2BD5A;
$yellow: #DDE6BD;
$gold: #EBE7C2;
$red: #DB3838;


$pink: #EA80FC;
$scarlet: #f44336;
$orange: #ff9100;

$box: 1230px;

$lexend: 'Lexend', sans-serif;
$sora: 'Sora', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$icomoon: 'icomoon' !important;
$fontawesome: "Font Awesome 5 Free";

//breakpoints
$breakpoints: (
    'hd': 1919px,
    'hg': 1600px,
    'lg': 1400px,
    'nm': 1250px,
    'md': 991px,
    'sm': 768px,
    'xs': 480px,
    'xxs': 385px
)!default;

$bfs: 21;