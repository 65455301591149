body {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	main {
		flex: 1 1 auto;
	}
	&.menu-opened {
		main {
			display: none;
		}
	}
}
.header-top {
	background-color: $gray;
	color: $white;
	font-family: $montserrat;
	font-size: rem(14);
	font-weight: 500;
	&-container {
		position: relative;
		max-width: 1000px;
		margin-left: auto;
		display: flex;
		column-gap: 1rem;
		align-items: center;
		padding: 0.3rem 1rem;
		justify-content: space-between;
		@include bdown(xs) {
			justify-content: flex-end;
		}
		.mobile-search-link {
			display: none;
			@include bdown(xs) {
				display: block;
				color: $white;
				margin-right: 1rem;
			}
		}
	}
	&-language {
		position: relative;
		padding-left: 2rem;
		padding-right: 2rem;
		@include bdown(sm) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			width: 2px;
			height: 23px;
			display: block;
			background-color: $gray1;
		}
		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: 2px;
			height: 23px;
			display: block;
			background-color: $gray1;
		}

		.active-language {
			display: flex;
			padding-right: 1.5rem;
			padding-left: 0.5rem;
			column-gap: 0.5rem;
			align-items: center;

			&::after {
				content: "\f078";
				font-family: $fontawesome;
				color: $white;
				font-weight: 600;
				position: absolute;
				display: block;
				right: 2rem;
				top: -4px;
				font-size: rem(20);
				z-index: 5;
				@include bdown(sm) {
					right: 1rem;
				}
			}
		}
		ul {
			position: absolute;
			top: 30px;
			background-color: $gray;
			z-index: 10;
			padding-right: 2.5rem;
			padding-left: 0.5rem;
			margin-bottom: 0;

			li {
				margin-bottom: 0.5rem;
				&.language-switcher-dropdown {
					display: none;
				}
				a {
					display: flex;
					column-gap: 0.5rem;
					align-items: center;
				}
			}
		}
	}
	&-social {
		display: flex;
		font-size: rem(15);
		margin-bottom: 0;
		&-icon {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			border: 1px solid $white;
			border-radius: 5px;
			min-width: 27px;
			min-height: 27px;
			margin-right: 0.5rem;
			@include bdown(md) {
				align-items: center;
				min-width: 24px;
				min-height: 24px;
			}
		}
	}
}

.header-bottom {
	padding: 0.5rem 1rem;
	background-color: $beige;
	color: $base;
	font-family: $sora;
	font-weight: 700;
	font-size: rem(15);
	a {
		text-decoration: none;

		
		&:hover,
		&.active {
			color: $greenalt;
			border-color: $green;
		}
	}
	&-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		max-width: 1600px;
		margin: auto;
	}
	&-logo-block {
		a {
			display: block;

			img {
				height: 50px;
			}
		}
		@include bdown(nm) {
			flex-basis: 20%;
		}
		@include bdown(md) {
			flex-basis: 50%;
		}
	}
	nav {
		font-size: 14px;
		z-index: 5;
		ul {
			display: flex;
			margin: 0;
			li {
				margin-right: 0.5rem;
				&:last-child {
					margin-right: 0;
				}
			}
			a {
				padding: 0.3rem;
				border-bottom: 6px solid transparent;
				@include bdown(nm) {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
		@include bdown(md) {
			flex: 1 1 100%;
			display: none;
			margin-left: calc(-15px - 1%);
			margin-right: calc(-15px - 1%);
			width: 100%;
			ul {
				min-height: calc(100vh - 110px);
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;
				a {
					width: 100%;
					text-align: center;
				}
			}
		}
	}
	&-hamburger {
		background-color: transparent;
		border: none;
		cursor: pointer;
		display: flex;
		padding: 0;
		&:focus {
			outline: none;
		}
		@include bup(md) {
			display: none;
		}
		svg {
			height: 52px;
			width: auto;
		}
		.line {
			fill: none;
			stroke: $base;
			stroke-width: 6;
			transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
				stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
			&1 {
				stroke-dasharray: 60 207;
				stroke-width: 6;
			}
			&2 {
				stroke-dasharray: 60 60;
				stroke-width: 6;
			}
			&3 {
				stroke-dasharray: 60 207;
				stroke-width: 6;
			}
		}
		&.opened {
			.line {
				&1 {
					stroke-dasharray: 90 207;
					stroke-dashoffset: -134;
					stroke-width: 6;
				}
				&2 {
					stroke-dasharray: 1 60;
					stroke-dashoffset: -30;
					stroke-width: 6;
				}
				&3 {
					stroke-dasharray: 90 207;
					stroke-dashoffset: -134;
					stroke-width: 6;
				}
			}
		}
	}
}
