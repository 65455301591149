.text {
	&-yellow {
		color: $yellow;
	}

	&-purple {
		color: $purple;
	}
	&-purplealt {
		color: $purplealt;
	}

	&-white {
		color: $white;
	}

	&-beige {
		color: $beige;
	}

	&-gray {
		color: $gray;
	}

	&-black {
		color: $black;
	}

	&-black-alt {
		color: $blackalt;
	}

	&-base {
		color: $base;
	}

	&-darkgray {
		color: $darkgray;
	}

	&-orange {
		color: $orange;
	}

	&-scarlet {
		color: $scarlet;
	}

	&-red {
		color: $red;
	}

	&-pink {
		color: $pink;
	}

	&-blue {
		color: $blue;
	}

	&-green {
		color: $green;
	}
	&-gold {
		color: $gold;
	}
}

.bg {
	&-yellow {
		background-color: $yellow;
	}

	&-purple {
		background-color: $purple;
	}
	&-purple1 {
		background-color: $purple1;
	}

	&-white {
		background-color: $white;
	}

	&-beige {
		background-color: $beige;
	}

	&-gray {
		background-color: $gray;
	}
	&-gray2 {
		background-color: $gray2;
	}

	&-darkgray {
		background-color: $darkgray;
	}

	&-black {
		background-color: $black;
	}

	&-black-alt {
		background-color: $blackalt;
	}

	&-base {
		background-color: $base;
	}

	&-orange {
		background-color: $orange;
	}

	&-scarlet {
		background-color: $scarlet;
	}

	&-red {
		background-color: $red;
	}

	&-pink {
		background-color: $pink;
	}

	&-blue {
		background-color: $blue;
	}

	&-green {
		background-color: $green;
	}
	&-greenalt {
		background-color: $greenalt;
	}
}

body.loaded {
	a {
		opacity: 1;
	}
	a,
	button {
		@include transition;
	}
}

a,
button {
	&:not(.disabled):not(:disabled) {
		cursor: pointer;
	}

	&.disabled,
	&:disabled {
		cursor: default;
		pointer-events: none;
	}

	transition: none;
}

a {
	text-decoration: underline;

	&:not(.btn) {
		&:hover {
			opacity: 0.75;
		}
	}
}

html {
	font-size: #{$bfs}px;

	@include bdown(md) {
		font-size: 16px;
	}
}

body {
	line-height: 1.5;
	color: $base;
	font-family: $lexend;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1-style,
.h2-style,
.h3-style,
.h4-style,
.h5-style,
.h6-style {
	line-height: normal;
	font-family: $sora;
}

h1,
.h1-style {
	font-size: em(55);
}

h2,
.h2-style {
	font-size: em(40);
	font-weight: 700;
}

h3,
.h3-style {
	font-size: em(35);
	font-weight: 700;
}

h4,
.h4-style {
	font-size: em(21);
	font-weight: 700;
}

h5,
.h5-style {
	font-size: em(23);
	@include letter-spacing(100);
	@include rlh(2);
}

h6,
.h6-style {
	font-size: em(16);
	font-weight: 700;
}

p,
ul,
ol {
	margin-bottom: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.6em;
}

blockquote {
	border-left: rem(10) solid currentColor;
	padding: 2rem;
	margin-left: 2rem;
	margin-bottom: 1rem;
	font-style: italic;

	& > *:last-child {
		margin-bottom: 0;
	}
}

section {
	ul {
		li {
			list-style-type: disc;
			margin-left: 1em;
		}
	}

	ol {
		li {
			margin-left: 1em;
		}
	}
}
ul {
	&.list-style-none {
		li {
			list-style-type: none !important;
		}
	}
	&.list-style-dots {
		li {
			list-style-type: disc !important;
		}
	}
	&.list-style-triangle {
		li {
			list-style-type: disclosure-closed !important;
		}
	}
}

header a,
footer a {
	display: inline-block;
}

.design-block {
	a:not(.default) {
		text-decoration: none;

		&:not(.btn) {
			&:hover {
				opacity: 1;
			}
		}
	}

	ul:not(.default),
	ol:not(.default) {
		li {
			list-style-type: none;
		}
	}
}

img {
	&.img-fw {
		width: 100%;
	}
}

a {
	obj {
		pointer-events: none;
	}
}

.btn {
	padding: 1rem 1.2rem;
	text-align: center;
	text-decoration: none;
	font-size: rem(16);
	font-family: $montserrat;
	font-weight: 700;
	border: 1px solid $black;
	color: $base;
	background-color: transparent;
	display: inline-block;
	&.no-arrow {
		&::after {
			content: "";
		}
	}
	&::after {
		color: $white;
		content: "\e902";
		padding-left: 1rem;
		vertical-align: middle;
		display: inline-block;
		font-family: $icomoon;
		font-size: rem(12);
		@include transition;
	}
	&.active,
	&:hover {
		background-color: $base;
		color: $white;
	}
	@include bdown(md) {
		font-size: 1rem;
	}
	@include bup(md) {
		min-width: 207px;
	}

	&.bold {
		font-weight: 700;
	}

	&-small {
		// @include fluid-font(12px);
		font-size: 0.7rem;
		@include bdown(md) {
			font-size: 0.9rem;
		}
		@include bup(md) {
			min-width: 125px;
		}
	}
	&-large {
		@include bup(md) {
			min-width: 253px;
		}
	}

	&-inverted {
		background-color: $base;
		color: #fff;

		&:hover {
			background-color: transparent;
			color: $base;
			&::after {
				color: $base;
			}
		}
	}

	&-white {
		background-color: $white;

		&:hover {
			background-color: $black;
			color: $white;
		}
	}

	&-gray {
		border: 1px solid $gray;
		color: $gray;

		&:hover {
			background-color: $gray;
			color: $black;
		}

		&-inverted {
			border: 1px solid $gray;
			background-color: $gray;
			color: $black;

			&:hover {
				border: 1px solid $gray;

				// background-color: $black;
				background-color: transparent;
				color: $gray;
			}
		}
	}

	&-darkgray {
		border: 1px solid $darkgray;
		color: $darkgray;

		&:hover {
			background-color: $darkgray;
			color: $white;
		}

		&-inverted {
			border: 1px solid $darkgray;
			background-color: $darkgray;
			color: $white;

			&:hover {
				border: 1px solid $darkgray;
				background-color: transparent;
				color: $darkgray;
			}
		}
	}

	&-yellow {
		border: 1px solid $yellow;
		color: $yellow;
		&::after {
			color: $yellow;
		}
		&:hover {
			background-color: $yellow;
			color: $black;
		}
		&-inverted {
			border: 1px solid $yellow;
			background-color: $yellow;
			color: $white;
			&::after {
				color: $white;
			}
			&:hover {
				border: 1px solid $yellow;
				// background-color: $black;
				background-color: transparent;
				color: $yellow;
				&::after {
					color: $yellow;
				}
			}
		}
	}

	&-purple {
		border: 1px solid $purple;
		color: $purple;
		&::after {
			color: $purple;
		}
		&:hover {
			background-color: $purple;
			color: $black;
		}
		&-inverted {
			border: 1px solid $purple;
			background-color: $purple;
			color: $white;
			&::after {
				color: $white;
			}
			&:hover {
				border: 1px solid $purple;
				// background-color: $black;
				background-color: transparent;
				color: $purple;
				&::after {
					color: $purple;
				}
			}
		}
	}

	&-purplealt {
		border: 1px solid $purplealt;
		color: $purplealt;
		&::after {
			color: $purplealt;
		}
		&:hover {
			background-color: $purplealt;
			color: $black;
		}
		&-inverted {
			border: 1px solid $purplealt;
			background-color: $purplealt;
			color: $white;
			&::after {
				color: $white;
			}
			&:hover {
				border: 1px solid $purplealt;
				// background-color: $black;
				background-color: transparent;
				color: $purplealt;
				&::after {
					color: $purplealt;
				}
			}
		}
	}

	&-pink {
		border: 1px solid $pink;
		color: $pink;

		&:hover {
			background-color: $pink;
			color: $base;
		}

		&-inverted {
			// border: 1px solid $white;
			border: 1px solid $pink;
			background-color: $pink;
			color: $base;

			&:hover {
				border: 1px solid $pink;

				// background-color: $white;
				background-color: transparent;
				color: $pink;
			}
		}
	}

	&-blue {
		border: 1px solid $blue;
		color: $blue;

		&:hover {
			background-color: $blue;
			color: $base;
		}

		&-inverted {
			// border: 1px solid $white;
			border: 1px solid $blue;
			background-color: $blue;
			color: $base;

			&:hover {
				border: 1px solid $blue;

				// background-color: $white;
				background-color: transparent;
				color: $blue;
			}
		}
	}

	&-green {
		border: 1px solid $green;
		color: $green;
		&::after {
			color: $green;
		}
		&:hover {
			background-color: $yellow;
			color: $black;
		}
		&-inverted {
			border: 1px solid $green;
			background-color: $green;
			color: $white;
			&::after {
				color: $white;
			}
			&:hover {
				border: 1px solid $green;
				// background-color: $black;
				background-color: transparent;
				color: $green;
				&::after {
					color: $green;
				}
			}
		}
	}

	&-scarlet {
		border: 1px solid $scarlet;
		color: $scarlet;

		&:hover {
			background-color: $scarlet;
			color: $white;
		}

		&-inverted {
			background-color: $scarlet;
			border: 1px solid $scarlet;
			color: $white;

			&:hover {
				border: 1px solid $scarlet;

				// background-color: $white;
				background-color: transparent;
				color: $scarlet;
			}
		}
	}

	&-orange {
		border: 1px solid $orange;
		color: $orange;

		&:hover {
			background-color: $orange;
			color: $white;
		}

		&-inverted {
			background-color: $orange;
			color: $white;
			border: 1px solid $orange;

			&:hover {
				border: 1px solid $orange;

				// background-color: $white;
				background-color: transparent;
				color: $orange;
			}
		}
	}

	&-red {
		border: 1px solid $red;
		color: $red;

		&:hover {
			background-color: $red;
			color: $white;
		}

		&-inverted {
			background-color: $red;
			border: 1px solid $red;
			color: $white;

			&:hover {
				border: 1px solid $red;

				// background-color: $white;
				background-color: transparent;
				color: $red;
			}
		}
	}

	&-rounded {
		border-radius: 1rem;
	}

	&[class^="btn-animated"],
	&[class*=" btn-animated"] {
		border: none !important;
		position: relative;
		overflow: hidden;

		span {
			z-index: 5;
			position: relative;
		}

		&:hover {
			&:before {
				right: -50%;
				top: -50%;
			}
		}

		// overflow: hidden;
		&:before {
			content: "";
			display: block;
			position: absolute;
			z-index: 1;
			border-radius: 50%;
			top: -5px;
			transition: all 0.7s;
			width: 200%;
			height: 300%;
			right: 100%;
		}

		&[class*="-from-white"] {
			background-color: $white;
			color: $black;
		}

		&[class*="-from-black"] {
			background-color: $black;
			color: $white;
		}

		&[class*="-from-gray"] {
			background-color: $gray;
			color: $black;
		}

		&[class*="-from-darkgray"] {
			background-color: $darkgray;
			color: $white;
		}

		&[class*="-from-blue"] {
			background-color: $blue;
			color: $black;
		}

		&[class*="-from-green"] {
			background-color: $green;
			color: $black;
		}

		&[class*="-from-pink"] {
			background-color: $pink;
			color: $black;
		}

		&[class*="-from-yellow"] {
			background-color: $yellow;
			color: $white;
		}

		&[class*="-from-red"] {
			background-color: $red;
			color: $white;
		}

		&[class*="-from-orange"] {
			background-color: $orange;
			color: $white;
		}

		&[class*="-from-scarlet"] {
			background-color: $scarlet;
			color: $white;
		}

		&[class*="-from-purple"] {
			background-color: $purple;
			color: $white;
		}

		&[class*="-to-pink"] {
			&:hover {
				color: $black;
			}

			&:before {
				background-color: $pink;
			}
		}

		&[class*="-to-blue"] {
			&:hover {
				color: $black;
			}

			&:before {
				background-color: $blue;
			}
		}

		&[class*="-to-green"] {
			&:hover {
				color: $black;
			}

			&:before {
				background-color: $green;
			}
		}

		&[class*="-to-yellow"] {
			&:hover {
				color: $black;
			}

			&:before {
				background-color: $yellow;
			}
		}

		&[class*="-to-red"] {
			&:hover {
				color: $white;
			}

			&:before {
				background-color: $red;
			}
		}

		&[class*="-to-purple"] {
			&:hover {
				color: $white;
			}

			&:before {
				background-color: $purple;
			}
		}

		&[class*="-to-scarlet"] {
			&:hover {
				color: $white;
			}

			&:before {
				background-color: $scarlet;
			}
		}

		&[class*="-to-orange"] {
			&:hover {
				color: $white;
			}

			&:before {
				background-color: $orange;
			}
		}

		&[class*="-to-white"] {
			&:hover {
				color: $black;
			}

			&:before {
				background-color: $white;
			}
		}

		&[class*="-to-black"] {
			&:hover {
				color: $white;
			}

			&:before {
				background-color: $black;
			}
		}

		&[class*="-to-gray"] {
			&:hover {
				color: $black;
			}

			&:before {
				background-color: $gray;
			}
		}

		&[class*="-to-darkgray"] {
			&:hover {
				color: $white;
			}

			&:before {
				background-color: $gray;
			}
		}
	}
}

.table {
	&-container {
		overflow-x: auto;
	}

	&-fw {
		width: 100%;
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	font-size: inherit;

	td,
	th {
		vertical-align: top;
		border: 1px solid $gray;
		padding: 5px;
	}

	th {
		font-weight: 700;
		text-align: center;
	}

	&.text {
		&-left {
			td,
			th {
				text-align: left;
			}
		}

		&-right {
			td,
			th {
				text-align: right;
			}
		}

		&-center {
			td,
			th {
				text-align: center;
			}
		}
	}

	&.table-inverted {
		color: $beige;
	}

	&.table-valign {
		&-top {
			td,
			th {
				vertical-align: top;
			}
		}

		&-middle {
			td,
			th {
				vertical-align: middle;
			}
		}

		&-bottom {
			td,
			th {
				vertical-align: bottom;
			}
		}
	}
}

xmp {
	font-size: 0.7rem;
	font-family: monospace;
}

.code-container {
	overflow-x: auto;
	background-color: $gray;
}

@keyframes moveInBottom {
	0% {
		opacity: 0;
		transform: translateY(30px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}
iframe {
	&.map {
		width: 100%;
		height: 630px;
	}
}
.report-embed {
	iframe {
		height: 500px;
	}
}